export const SAT_CONFRONTS_TAG_EVENTS = {
  // Events related with the metadata request list screen
  CONSULTED_METADATA_REQUEST_SAT_CONFRONTS:
    'ConsultedMetadataRequestSatConfronts',
  SUCCESS_METADATA_REQUEST_SAT_CONFRONTS: 'SuccessMetadataRequestSatConfronts',
  ERROR_METADATA_REQUEST_SAT_CONFRONTS: 'ErrorMetadataRequestSatConfronts',
  DOWNLOAD_METADATA_REQUEST_SAT_CONFRONTS_AS_CSV:
    'DownloadMetadataRequestSatConfrontsAsCSV',
  DOWNLOAD_METADATA_REQUEST_SAT_CONFRONTS_AS_XLSX:
    'DownloadMetadataRequestSatConfrontsAsXLSX',

  // Events related with the SAT confronts detail screen
  CONSULTED_METADATA_REQUEST_DETAIL_SAT_CONFRONTS:
    'ConsultedMetadataRequestDetailSatConfronts',

  // Events related with the SAT confronts request screen
  REQUEST_SAT_CONFRONTS: 'RequestSatConfronts',
  SUCCESS_SAT_CONFRONTS: 'SuccessSatConfronts',
  ERROR_SAT_CONFRONTS: 'ErrorSatConfronts',
  DOWNLOAD_SAT_CONFRONTS_AS_XLSX: 'DownloadDetailSatConfrontsAsXLSX',
  DOWNLOAD_SAT_CONFRONTS_AS_CSV: 'DownloadDetailSatConfrontsAsCSV',

  // When the user successfully adds a company fiel
  SUCCESS_ADD_COMPANY_FIEL_FILE: 'SuccessAddCompanyFielFile',
  // When theres an error adding a company fiel
  ERROR_ADD_COMPANY_FIEL_FILE: 'ErrorAddCompanyFielFile',
  DELETED_COMPANY_FIEL_FILE: 'DeletedCompanyFielFile',
};
