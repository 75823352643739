import { defineStore } from 'pinia';

export const useTerminationAndSettlementStore = defineStore({
  id: 'terminationAndSettlement',

  state: () => ({
    initSimulatorType: null,
    simulators: null,
    stepsData: {
      simulators: null,
      incidences: [],
      absenteeism: [],
      inabilities: [],
      concepts: [],
      results: [],
    },
    catalogs: {
      employees: [],
      payrolls: [],
      leavingMotives: [],
      concepts: [],
      absenteeismTypes: [],
    },
  }),

  actions: {
    setInitSimulatorType(value) {
      this.initSimulatorType = value;
    },
    setSimulators(value) {
      this.simulators = value;
    },
    setCatalogs(value) {
      this.catalogs = value;
    },
    setCatalogByKey(key, value) {
      this.catalogs[key] = value;
    },
    setStepsDataByKey(key, value) {
      this.stepsData[key] = value;
    },
    resetStepsData() {
      this.stepsData = {
        simulators: null,
        incidences: [],
        absenteeism: [],
        inabilities: [],
        concepts: [],
        results: [],
      };
    },
    resetCatalogs() {
      this.catalogs = {
        employees: [],
        payrolls: [],
        leavingMotives: [],
        concepts: [],
        absenteeismTypes: [],
      };
    },

    resetStore() {
      this.setInitSimulatorType(null);
      this.setSimulators(null);
      this.resetStepsData();
      this.resetCatalogs();
    },
  },

  getters: {
    getInitSimulatorType: (state) => state.initSimulatorType,
    getSimulators: (state) => state.simulators,
    getCatalogs: (state) => state.catalogs,
    getCatalogByKey: (state) => (key) => state.catalogs[key],
    getStepsDataByKey: (state) => (key) => state.stepsData[key],
  },
});
