<template>
  <div class="default relative flex min-h-screen">
    <Loading v-if="loading" />
    <SideBar
      v-if="!hideZentricCustomization && !loading"
      :is-layouts-app-user="isLayoutsAppUser"
    />
    <div class="relative flex min-h-screen flex-grow flex-col">
      <ZHeader
        v-if="!hideZentricCustomization && !loading"
        class="sticky left-0 top-0 z-11"
        :is-layouts-app-user="isLayoutsAppUser"
        :is-expanded-menu="isExpandedMenu"
      />
      <BannerComponent
        class="sticky left-0 top-16 z-11"
        :class="{
          'pl-20': !hideZentricCustomization,
        }"
      />

      <div
        v-if="!loading"
        class="h-[calc(100vh - 120px)] grid flex-grow grid-cols-1 overflow-auto bg-worky-background pr-16px transition-all duration-500"
        :class="!isExpandedMenu ? 'pl-96px' : 'pl-272px'"
      >
        <Nuxt class="col-span-1" />
      </div>
    </div>
    <div>
      <ToastComponent />
      <SessionModal
        v-if="showInactivityModal"
        :timer="idleTimer"
        :disabled="validateOnInactivity"
        @logout="deleteSession"
        @login="restoreSession"
      />
    </div>
  </div>
</template>
<script>
  // MIXINS
  import inactivityValidation from '~/mixins/auth/inactivityValidation.js';
  import login from '~/mixins/auth/login.js';
  import logout from '~/mixins/auth/logout.js';
  import validateAuth from '~/mixins/auth/validateAuth.js';
  import getData from '~/mixins/forms/getData';
  import validators from '~/mixins/forms/validators';

  export default {
    name: 'DefaultLayout',
    components: {
      Loading: () => import('~/components/ui/loaders/Loading.vue'),
      SideBar: () => import('~/components/layout/SideBar.vue'),
      ZHeader: () => import('~/components/layout/ZHeader.vue'),
      ToastComponent: () => import('~/components/ui/toast/ToastComponent.vue'),
      BannerComponent: () =>
        import('~/components/ui/toast/BannerComponent.vue'),

      SessionModal: () => import('~/components/ui/modals/SessionModal.vue'),
    },
    mixins: [
      validateAuth,
      getData,
      logout,
      login,
      inactivityValidation,
      validators,
    ],
    middleware: ['sessionData', 'license'],
    data() {
      return {
        isLayoutsAppUser:
          this.$authentication.getUserConfigByVariable('layouts')?.value ===
          '1',
        hideZentricCustomization: false,
        showLostSessionAlert: true,
      };
    },
    head() {
      const title =
        this.$route.meta.title || this.isWhiteLabel
          ? 'Nomina precisa y profesional'
          : 'Worky nómina profesional y precisa';
      return {
        title,
      };
    },
    computed: {
      isWhiteLabel() {
        return localStorage.getItem('white-label') === 'true';
      },
      loginPath() {
        // Se usa dentro del mixin validateAuth
        const { isLayoutsAppUser } = this;
        return isLayoutsAppUser
          ? '/layouts-app/login/'
          : this.isWhiteLabel
            ? '/auth/'
            : '/login/';
      },
      isExpandedMenu() {
        return this.$preferences.getIsExpandedMenu();
      },
    },
  };
</script>
