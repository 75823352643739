<template>
  <div class="toastWrapper z-1000 fixed">
    <transition-group name="transition-toast">
      <div
        v-for="toast in getToasts"
        :key="toast.id"
        class="toast relative mb-4 flex max-h-92px min-h-72px w-484px items-center gap-16px overflow-hidden rounded-md border-l-[6px] p-4"
        :class="`toast-color-${toast.type}`"
      >
        <div :class="`icon-color-${toast.type}`">
          <fa
            :icon="['fas', icons[toast.type]]"
            class="h-32px w-32px fill-current"
          />
        </div>
        <div class="flex flex-col">
          <h4
            class="font-montserrat text-16px font-semibold text-worky-neutral-600"
          >
            {{ toast.message }}
          </h4>
          <p
            class="tooltip-description max-h-48px text-12px text-worky-neutral-500"
          >
            {{ toast.description }}
          </p>
        </div>
        <div
          class="closeIcon absolute cursor-pointer text-worky-neutral-600"
          @click="closeHandler(toast)"
        >
          <fa icon="xmark" class="h-20px w-20px fill-current" />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  export default {
    name: 'ToastComponent',
    data() {
      return {
        icons: {
          success: 'circle-check',
          alert: 'triangle-exclamation',
          warn: 'triangle-exclamation',
          info: 'circle-info',
          error: 'circle-xmark',
        },
      };
    },
    computed: {
      getToasts() {
        return this.$toasts.getAllToasts();
      },
    },
    mounted() {
      setTimeout(() => {
        document.querySelectorAll('.tooltip-description').forEach((element) => {
          this.ellipsis(element);
        });
      }, 1000);
    },
    methods: {
      ellipsis(el) {
        try {
          const elheight = el.clientHeight;
          let eltxt = el.innerHTML;
          while (elheight < el.scrollHeight) {
            eltxt = eltxt.substring(0, eltxt.length - 1);
            el.innerHTML = eltxt + '...';
          }
        } catch (e) {}
      },
      closeHandler(toast) {
        this.$toasts.removeToast(toast);
      },
    },
  };
</script>
<style scoped>
  .icon-color-success {
    @apply text-worky-success-400;
  }

  .icon-color-alert {
    @apply text-worky-warn-400;
  }

  .icon-color-error {
    @apply text-worky-error-300;
  }

  .icon-color-info {
    @apply text-worky-info-500;
  }

  .toast-color-success {
    @apply border-worky-success-400 bg-worky-success-100;
  }

  .toast-color-alert {
    @apply border-worky-warn-400 bg-worky-warn-100;
  }

  .toast-color-error {
    @apply border-worky-error-300 bg-worky-error-100;
  }

  .toast-color-info {
    @apply border-worky-info-500 bg-worky-info-100;
  }

  .toastWrapper {
    top: 10%;
    right: 1%;
  }
  .closeIcon {
    top: 0%;
    right: 0%;
    width: 20px;
    height: 20px;
  }
  .transition-toast-enter {
    transform: translateX(100%);
    opacity: 0;
  }
  .transition-toast-enter-active {
    transition:
      transform 0.5s ease,
      opacity 0.5s ease;
  }
  .transition-toast-enter-to {
    transform: translateX(-0%);
    opacity: 1;
  }
  .transition-toast-leave {
    transform: translateX(-0%);
    opacity: 1;
  }
  .transition-toast-leave-active {
    transition:
      transform 0.5s ease,
      opacity 0.5s ease;
  }
  .transition-toast-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
</style>
