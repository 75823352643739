import { useTerminationAndSettlementStore } from '~/stores/terminationAndSettlementStore';
export default class TerminationAndSettlementProvider {
  constructor() {
    this.terminationAndSettlement = useTerminationAndSettlementStore();
  }

  getInitSimulatorType() {
    return this.terminationAndSettlement.getInitSimulatorType;
  }

  getSimulators() {
    return this.terminationAndSettlement.getSimulators;
  }

  getCatalogs() {
    return this.terminationAndSettlement.getCatalogs;
  }

  getCatalogByKey(key) {
    return this.terminationAndSettlement.getCatalogByKey(key);
  }

  getStepsDataByKey(key) {
    return this.terminationAndSettlement.getStepsDataByKey(key);
  }

  setInitSimulatorType(value) {
    this.terminationAndSettlement.setInitSimulatorType(value);
  }

  setSimulators(value) {
    this.terminationAndSettlement.setSimulators(value);
  }

  setCatalogs(value) {
    this.terminationAndSettlement.setCatalogs(value);
  }

  setCatalogByKey(key, value) {
    this.terminationAndSettlement.setCatalogByKey(key, value);
  }

  setStepsDataByKey(key, value) {
    this.terminationAndSettlement.setStepsDataByKey(key, value);
  }

  resetStepsData() {
    this.terminationAndSettlement.resetStepsData();
  }

  resetCatalogs() {
    this.terminationAndSettlement.resetCatalogs();
  }

  resetStore() {
    this.terminationAndSettlement.resetStore();
  }
}
