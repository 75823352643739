<template>
  <div class="default relative flex min-h-screen flex-col">
    <HeaderCart />
    <BannerComponent class="sticky left-0 right-0 top-16 z-11" />
    <Nuxt class="flex-grow bg-worky-background" />
    <ToastComponent />
  </div>
</template>

<script>
  export default {
    name: 'CartLayout',
    components: {
      HeaderCart: () => import('~/components/layout/HeaderCart.vue'),
      BannerComponent: () =>
        import('~/components/ui/toast/BannerComponent.vue'),
      ToastComponent: () => import('~/components/ui/toast/ToastComponent.vue'),
    },
  };
</script>
