// DOC: https://docs.google.com/spreadsheets/d/12U-64hE5fd9T036XapZSgjCs5TEjKN9ZzCN1lt2epZ0/edit?gid=0#gid=0
// EVENTS CATALOG: https://docs.google.com/spreadsheets/d/12U-64hE5fd9T036XapZSgjCs5TEjKN9ZzCN1lt2epZ0/edit?pli=1&gid=0#gid=0

export * from './employees-tag-events';
export * from './sua-tag-events';
export * from './reports-tag-events';
export * from './calculations-tag-events';
export * from './login-tag-events';
export * from './stampings-tag-events';
export * from './organigrams-tag-events';
export * from './calendars-tag-events';
export * from './payrolls-tag-events';
export * from './simulators-tag-events';
export * from './confronts-tag-events';

export const CATEGORY = {
  DEFAULT: 'Payroll',
  PayrollLogin: 'PayrollLogin',
};
